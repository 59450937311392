import Button from "../Button";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import Nut from "../Nut";
import { getCurrentLanguage } from "../../i18n";
import { t } from "i18next";
import "./TeamDescription.scss";
import NavContext from "../Layout/Nav/NavProvider";
import { useInView } from "../../hooks/useInView";

const TeamDescription: FC = () => {
  const currentLanguage = getCurrentLanguage();
  const { activeSlide, isMobile } = useContext(NavContext);
  const [slideChanged, setSlideChanged] = useState(false);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);

  const teamDescriptionRef = useRef<HTMLDivElement>();
  const inViewDescription = useInView(teamDescriptionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.05,
  }, true);

  useEffect(() => {
    if (!slideChanged && activeSlide != 0) {
      setSlideChanged(true);
    }
  }, [activeSlide]);

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile]);

  const getStateClass = () => {
    return inViewDescription ? "is-active" : "is-prev";
  };

  return (
    <div ref={teamDescriptionRef}
      className={`team-description ${isMobileActive ? getStateClass() : ""}`}
    >
      <div
        className={`team-description__content ${
          slideChanged ? "" : "is-initial"
        }`}
      >
        <h2 className="team-description__h2">{t("team.h2")}</h2>
        <div className="team-description__wrapper">
          <p className="team-description__text">
            {t('team.description')}
          </p>
          <p className="team-description__text">
            {t('team.descriptionShort')}
          </p>
          <span className="team-description__button">
            <Button
              isLink
              solid
              uppercase
              href={`/${currentLanguage}/contacto`}
            >
              {t("team.joinToOurTeam")}
            </Button>
          </span>
          <div className="team-description__nut-wrapper is-mobile">
            <div className="team-description__nut">
              <Nut color={"#000000"} rotate />
            </div>
          </div>
        </div>
      </div>
      <div className="team-description__nut-wrapper">
        <div className="team-description__nut">
          <Nut color={"#000000"} rotate />
        </div>
      </div>
    </div>
  );
};

export default TeamDescription;
