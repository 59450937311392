import Button from "../Button";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import "./TeamBio.scss";
import NavContext from "../Layout/Nav/NavProvider";
import { useInView } from "../../hooks/useInView";
import DataPanel from '../DataPanel';
import DataPanelItem from '../DataPanel/DataPanelItem';

const TeamBio: FC = () => {
  const { isMobile } = useContext(NavContext);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);
  const [blogButtonIsHover, setBlogButtonIsHover] = useState(false);  

  const deValSocialLinks = {
    Twitter: 'https://twitter.com/luisdeval?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
    Instagram: 'https://www.instagram.com/valdeman/?hl=es'
  };
  const deValRef = useRef<HTMLDivElement>();
  const inViewDeVal = useInView(deValRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1 
  }, true);
  
 const getStateClass = () => {
    return inViewDeVal
      ? "is-active"
      : "is-next";
  };

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  return <div ref={deValRef} className={`team-bio ${isMobileActive ? getStateClass() : ''}`}>
  <div className="team-bio__content">
    <div className="team-bio__de-val">
      <div className="team-bio__image-wrapper">
        <img src={`/images/team/de-val.png`} className="team-bio__image" />
      </div>
      <div className="team-bio__data-wrapper">
        <div className="team-bio__data">
          <h2 className="team-bio__h2">Luis de Val</h2>
          <div className='team-bio__data-component'>
            <DataPanel inView={inViewDeVal}>
              <DataPanelItem title="Plataformas" alignedToRight>
                {
                  Object.entries(deValSocialLinks)
                    .map(([key, value]) => {
                      return <a key={`social-link-${key}`} href={value as string}>{key}</a>;
                    })
                }
              </DataPanelItem>
            </DataPanel>
          </div>
        </div>
        
        <div className="team-bio__data-description-wrapper">
          <div
            className="team-bio__data-description scrollable custom-scrollbar cursor-click"
            data-cursor-text="Scroll"
            data-cursor-text-color="#fff"
          >
            
            {Object.values(t('team.teamBioParagraphs', { returnObjects: true })).map((element, idx) => (
              <p key={idx}>{element}</p>
            ))}
          </div>
        </div>

        <span className="team-bio__blog-button">
          <Button
            isLink
            solid
            uppercase
            isTargetBlank
            mouseEnterHandler={() => setBlogButtonIsHover(true)}
            mouseLeaveHandler={() => setBlogButtonIsHover(false)}
            href="https://luisdeval.com/"
          >
            <span className="team-bio__blog-button-text">{t('common.blog')}</span>
            <span className='team-bio__blog-arrow'>→</span>
          </Button>
        </span>
      </div>
    </div>
  </div>
</div>
};

export default TeamBio;
