import React, { FC } from "react"

export interface DataPanelItemProps {
  title: string;
  alignedToRight?: boolean;
}

const DataPanelItem: FC<DataPanelItemProps> = ({ title, alignedToRight, children }) => {
  return (
    <>
      <div className="data-panel__key">{title}</div>
      <div className={`data-panel__value ${alignedToRight ? 'data-panel__value--right' : ''}`}>{children}</div>
      <div className="data-panel__separator"></div>
    </>
  )
}
export default DataPanelItem
