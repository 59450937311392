import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import "./TeamDiscover.scss";
import ArrowMoreContent from "../ArrowMoreContent";
import NavContext from "../Layout/Nav/NavProvider";
import { useInView } from "../../hooks/useInView";

const TeamDiscover: FC = () => {
  const lens = useRef<HTMLDivElement>();
  const playZone = useRef<HTMLDivElement>();
  const [playing, setPlaying] = useState(false);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);

  const { isMobile } = useContext(NavContext);

  const onMouseMoveHandler = (event: React.MouseEvent) => {
    if (!lens.current || !playZone.current) {
      return;
    }

    const playZoneCR = playZone.current.getBoundingClientRect();
    const { clientX, clientY } = event;
    if (
      clientX < playZoneCR.x ||
      clientY < playZoneCR.y ||
      clientX > playZoneCR.x + playZoneCR.width ||
      clientY > playZoneCR.y + playZoneCR.height
    ) {
      setPlaying(false);
      return;
    }

    setPlaying(true);
    lens.current.style.left = `${clientX - playZoneCR.x}px`;
    lens.current.style.top = `${clientY - playZoneCR.y}px`;
  };

  const shuffledArr = (array) =>
    array
      .map((a) => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value);

  const teamNames = [
    "Marc Furió Castelló",
    "Júlia Roig Sellers",
    "Daniel Viladecans Jiménez",
    "María Laura Maglia",
    "Laura Cedillo Azaustre",
    "Jan Masia Esteve",
    "Carlos García Concha",
    "Pol Rosario Ortega ",
    "Paula Esteban Algarte",
    "Joan Forcada Puig",
    "Mateo Gómez García",
    "Marc Mayolas Sancho",
    "Daniel Rofín Gimeno",
    "Mariona Esteban Hernández",
    "Sandra Marcén Sala",
    "Blanca Rochela Martínez",
    "David García Cañestro",
    "Nerea Cuartero Martínez",
    "Sergi Brunet Vilanova",
    "Kevin Prieto Vallecillo",
    "Julia López Villalobos",
    "Georgina Sotorra Muñoz",
    "Jaume Miranda Vila",
    "Ariadna Valls Muñoz",
    "Iria Mudarra Huertas",
    "Javier Coomonte Suarez",
    "Ana Sánchez Ongil",
    "Martina García Urrea",
    "Gorka Jiménez Peinado",
    "Andrés Reyes Ottonello",
    "Andrés Florido Granados  ",
    "María Vera De Prado",
    "Luis Rodríguez Ochoa",
    "Lorena Cuadrado Ramirez",
    "Teodora Stoyanova",
    "Antonella Villotta",
    "Alejandro Rosales Vega",
    "Flor Angrisani Sagre",
    "Bernardo Proietti Barcelo",
    "Gina Vidal Cambray",
    "Silvia Espín González",
    "Albert Lladó Quer",
    "Carmen Pardos Pascual",
    "Carla Rey Pérez",
    "Raquel Sánchez Pablo",
    "Elsa Grande García",
    "Albert Espel Gil",
    "Andrea Arévalo Trigo",
    "Édgar González Santana",
    "Jacob Ruiz Palomo",
    "Laura Marfá Bárzano",
    "Eloi Cruz Guerrero",
    "Íngrid García Gerrero",
    "Cristina Minguell Vidosa",
    "Arnau Estruch Fluvià",
    "Carla Bernardi",
    "Gerard Campmany Pérez",
    "Misael Liranzo Rodríguez",
    "Sheila Fernández Avellaneda",
    "Roser Carbonell Valero",
    "Vanesa Núñez Zuriaga",
    "Laura Cohen Marchena",
    "Alessandro Seroglia",
    "Héctor González Orozco",
    "Tànit Fernández Ciria",
    "Daniel Sánchez Mares",
    "Marta Cabeza Nogués",
    "Raül Asensio Contreras",
    "Attilio Magurano",
    "Alex Marín Gómez",
    "Jon Manzanos Ruiz",
    "Josep Cardona Martínez",
    "María Casals Miró",
    "Guillem Rufí Batiste",
    "Adrián Peña Tamayo",
    "Blanca Soler-Cabot Monpel",
    "Juan Miguel Mínguez Caro",
    "Toni Mostazo Rizo",
    "David Cajal Alcaine",
    "Luis de Val Mínguez",
    "Carles Ortolá",
    "Joan Solé",
    "Xin Guo",
    "Aixa Aranda",
    "Sara Valenciano",
    "Nina Bosch",
    "José María Martínez-Pujalte",
    "Yngrid Figlioli",
    "Natalia Coca",
    "Carlos Miralles",
    "Nuria Grau",
    "Laura Irún",
    "David Rubalcava Sauer ",
    "Angie Izquierdo",
  ];

  const totalLines = isMobileActive ? 4 : 10;

  const teamDiscoverRef = useRef<HTMLDivElement>();
  const inViewDiscover = useInView(
    teamDiscoverRef,
    {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    },
    true
  );

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile]);

  const getStateClass = () => {
    return inViewDiscover ? "is-active" : "is-next";
  };

  const buildLines = () => {
    return [...Array(totalLines).keys()].map((line) => {
      return (
        <span
          key={`team-play-line-${line}`}
          className="team-discover__play-line"
        >
          {shuffledArr(teamNames).map((name) => {
            return (
              <span key={`team-play-name-${line}-${name}`}>{name} · </span>
            );
          })}
        </span>
      );
    });
  };

  return (
    <div
      ref={teamDiscoverRef}
      className={`team-discover ${isMobileActive ? getStateClass() : ""}`}
    >
      <div className="team-discover__content">
        <div className="team-discover__team-wrapper">
          <div className="team-discover__team">
            <p>
              <span>{t("team.discover")}</span>
              <span>{t("team.our")}</span>
              <span>{t("team.team")}</span>
            </p>
            <div className="team-discover__arrow-play">
              <ArrowMoreContent
                color="#000"
                containerHeight="2rem"
                containerWidth="18rem"
                sideWidth="1rem"
                width=".2rem"
                direction="right"
                isAnimated
                clickHandler={null}
              />
            </div>
            <div className="team-discover__arrow-play is-mobile">
              <ArrowMoreContent
                color="#000"
                containerHeight="10rem"
                containerWidth="2rem"
                sideWidth="1rem"
                width=".2rem"
                isAnimated
                clickHandler={null}
              />
            </div>
          </div>
          <div
            ref={playZone}
            className={`team-discover__play cursor-click ${
              playing ? "team-discover__play--active" : ""
            }`}
            onMouseEnter={() => (!isMobileActive ? setPlaying(true) : null)}
            onMouseLeave={() => (!isMobileActive ? setPlaying(false) : null)}
            onMouseMove={!isMobileActive ? onMouseMoveHandler : null}
            data-cursor-disabled
          >
            {buildLines()}
            {!isMobileActive && (
              <div ref={lens} className="team-discover__lens"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDiscover;
