import "./DataPanel.scss"

import React, { FC } from "react"

export interface DataPanelProps {
  inView?: boolean;
  isAnimated?: boolean;
}

const DataPanel: FC<DataPanelProps> = ({ inView, isAnimated, children }) => {
  return (
    <div className={`data-panel ${isAnimated ? 'data-panel--animated' : 'data-panel--not-animated'} ${isAnimated && inView ? 'data-panel--in-view' : ''}`}>
      <div className="data-panel__separator"></div>      
      {children}
    </div>
  )
}
export default DataPanel
