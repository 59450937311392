import './Nut.scss';

import React, { FC } from "react"

export interface NutProps {
  color?: string;
  rotate?: boolean;
}

const Nut: FC<NutProps> = ({ color, rotate }) => {

  const nutSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 96.003357 95.933601"
      version="1.1"
      style={{ display: 'block' }}
    >
      <defs />
      <path
        d="m 75.225731,87.046992 -7.45631,-4.23721 -0.18803,8.57426 -6.55108,-5.53508 -1.76037,8.39329 -5.42219,-6.64422 -3.27272,7.92692 -4.109145,-7.52758 -4.6736,7.19067 -2.65572,-8.15481 -5.91537,6.2096 -1.11231,-8.50371 -6.95572,5.0172 0.46955,-8.56332 -7.759,3.65336 2.03482,-8.3312 -8.29839,2.16535 3.53096,-7.81579 -8.5548702,0.60396 4.9071402,-7.03369 -8.5202912,-0.97825 6.116111,-6.01239 -8.19538097,-2.5273 7.11659097,-4.78614 -7.59143097,-3.99027 7.87471097,-3.39654 -6.728881,-5.31742 8.364711,-1.89195 -5.63704,-6.46324 8.5700302,-0.32244 -4.3536302,-7.38928 8.4832502,1.25765 -2.92171,-8.06309 8.1079,2.79506 -1.39065,-8.46278 7.45666,4.23721 0.18803,-8.57427 6.55073,5.53509 1.76036,-8.39364 5.4222,6.64457 3.27307,-7.92692201 4.108795,7.52757201 4.6736,-7.19066901 2.65571,8.15445901 5.91538,-6.20959 1.11231,8.5037 6.95572,-5.01685 -0.46955,8.56333 7.75935,-3.65337 -2.03483,8.3312 8.29804,-2.16535 -3.53095,7.81544 8.55486,-0.6036 -4.90714,7.03368 8.52029,0.97825 -6.11575,6.01204 8.19502,2.52766 -7.11623,4.78578 7.59107,3.99027 -7.8747,3.3969 6.72888,5.31742 -8.36471,1.89194 5.63739,6.46289 -8.57039,0.32279 4.35399,7.38894 -8.4836,-1.2573 2.92205,8.06308 -8.10824,-2.79506 z"
        style={{ fill: 'none', stroke: color || '#ffffff', strokeWidth: 0.352778, strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', opacity: 1, }}
      />
    </svg>
  );
  
  if (rotate) {
    return <div className="nut-wrapper">{nutSvg}</div>
  }

  return nutSvg;
}

export default Nut
