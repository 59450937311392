import 'normalize.css';
import './index.scss';

import React, { FC, useContext, useEffect, useRef, useState } from 'react'

import  Layout  from '../../components/Layout'
import SEO from '../../components/SEO'
import NavContext, { NavProvider } from '../../components/Layout/Nav/NavProvider';

import HomeSliderContact from "../../components/HomeSliderContact";
import SectionHeader from '../../components/SectionHeader';
import Slider from '../../components/Slider';
import Project from '../../components/Project';
import { getCurrentLanguage } from "../../i18n";
import { TeamDescription } from '../../components/TeamDescription';
import { TeamDiscover } from '../../components/TeamDiscover';
import { TeamBio } from '../../components/TeamBio';
import { t } from 'i18next';

const PageTemplate: FC = () => {
  const { isMobile } = useContext(NavContext);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);

  const slug = typeof window !== 'undefined' ? window.location.pathname : '';
  const page: any = {}
  page.i18n_lang = getCurrentLanguage();

  const section = useRef<HTMLDivElement>();

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  function pageContent() {
    const elements: JSX.Element[] = [
      <div className={`team ${isMobileActive ? 'is-active' : ''}`}>
        <div className="team__video">
          <Project
            videoId="2tDy6AyCkD0"
            isDetail
            defaultMuted
            autoPlay
            isMobileAutoplayActive={isMobileActive}
          />
        </div>
      </div>,
      <TeamDescription />,
      <TeamDiscover />,
      <TeamBio />,
      <HomeSliderContact />
    ];
    return elements.map((element, index) => (
      <span key={index}>
        {element}
      </span>
    ));
  }
  
  return <NavProvider>
    <Layout
      language={page.i18n_lang}
      isHome={false}
    >
      <SEO
        title={t('team.pageTitle')}
        description={t('team.pageDescription')}
        lang={page.i18n_lang}
        slug={slug}
      />
      <SectionHeader h1={t('menu.team.title')} inView={true} />
      <section ref={section} className="team-container">
        {isMobileActive ? 
          pageContent() :
          <Slider>
            {pageContent()}
          </Slider>
        }
      </section> 
    </Layout>
  </NavProvider>
}

export default PageTemplate 
